import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;

  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 1080.000000 1080.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g
          fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd" transform="translate(0.000000,1080.000000) scale(0.100000,-0.100000)">
<path d="M5635 7643 c-152 -19 -299 -63 -422 -126 -95 -49 -119 -65 -190 -131
-48 -44 -58 -49 -108 -52 -30 -2 -55 1 -55 7 0 8 78 62 105 72 6 3 7 7 4 10
-6 8 -152 -66 -231 -117 -307 -196 -535 -523 -610 -871 -17 -81 -18 -379 0
-397 9 -9 12 -8 12 8 0 16 2 17 10 4 5 -8 10 -10 10 -5 0 6 17 17 38 24 20 8
43 16 50 19 7 2 12 1 11 -4 -4 -22 2 -33 15 -28 9 3 15 -5 19 -25 4 -17 11
-31 16 -31 6 0 6 12 -1 31 -17 50 -4 309 21 401 11 43 27 90 36 105 l16 28 -6
-40 c-22 -147 8 -357 74 -524 12 -30 20 -60 17 -67 -3 -8 3 -19 12 -26 26 -19
52 -64 53 -90 0 -13 3 -17 6 -10 4 10 11 8 29 -9 13 -12 24 -28 24 -35 0 -8 6
-11 14 -8 8 3 33 -8 57 -25 23 -17 48 -31 55 -31 7 0 14 -4 16 -9 4 -14 147
-91 167 -91 10 0 21 -4 26 -9 10 -9 87 -34 138 -45 17 -4 27 -13 27 -25 0 -24
33 -33 58 -15 18 13 62 9 62 -6 0 -15 -62 -61 -76 -56 -8 3 -14 -1 -14 -9 0
-16 -13 -20 -100 -34 l-55 -9 47 -1 c36 -1 46 -4 42 -15 -3 -9 3 -17 16 -21
24 -8 124 55 210 133 27 23 51 42 54 42 7 0 -32 -47 -81 -97 -25 -26 -28 -33
-15 -33 15 -1 15 -2 -1 -15 -18 -13 -18 -14 1 -26 12 -7 17 -19 15 -31 -7 -25
-103 -83 -182 -110 -79 -26 -223 -31 -288 -9 -37 12 -42 17 -40 43 2 28 3 28
44 22 50 -7 171 12 224 36 43 19 41 30 -5 30 -84 0 -304 82 -275 102 11 7 9 8
-5 4 -20 -7 -49 11 -40 25 3 5 -2 6 -10 3 -11 -4 -16 0 -16 10 0 9 -6 15 -12
14 -7 -2 -12 2 -11 9 1 6 -5 17 -15 25 -15 12 -16 11 -3 -4 7 -10 9 -18 4 -18
-13 0 -103 95 -128 135 -11 17 -22 32 -26 35 -3 3 -23 37 -44 75 -21 39 -41
75 -45 80 -4 6 -19 42 -33 80 -14 39 -26 62 -26 52 -1 -10 -17 -33 -36 -52
-29 -30 -41 -35 -79 -35 -26 0 -47 5 -50 13 -3 6 -2 -1 1 -18 13 -65 93 -237
146 -317 60 -90 161 -201 217 -239 19 -12 37 -26 40 -29 6 -7 85 -44 128 -60
17 -7 32 -16 32 -20 0 -15 155 -40 253 -40 149 0 345 45 422 97 22 15 85 18
85 4 0 -13 -91 -47 -215 -82 -108 -30 -98 -35 35 -18 127 15 244 47 350 93 82
37 230 122 175 101 -11 -4 -30 -10 -43 -12 -12 -3 -25 -9 -28 -14 -3 -5 -13
-9 -22 -9 -12 1 -10 5 8 15 14 8 22 14 19 15 -4 0 20 27 52 59 45 46 54 61 42
65 -12 5 -13 9 -3 21 7 8 8 15 3 15 -17 0 -6 45 21 89 36 56 108 196 126 241
7 19 19 51 27 70 8 19 23 64 33 100 l18 65 1 -57 c1 -32 6 -58 11 -58 6 0 10
5 10 12 0 8 3 9 10 2 12 -12 15 -58 6 -94 -3 -14 -4 -19 0 -12 11 23 21 12 28
-29 5 -33 3 -44 -10 -54 -10 -7 -19 -23 -21 -35 -2 -13 -8 -30 -15 -37 -6 -7
-8 -17 -4 -21 10 -9 46 35 46 57 0 13 10 17 41 18 38 2 48 9 126 90 47 48 97
104 112 123 14 19 38 48 53 63 16 16 28 37 28 47 0 10 7 20 15 24 8 3 15 11
15 18 0 7 7 27 16 43 9 17 21 78 27 138 20 201 -21 414 -114 588 -10 20 -19
52 -19 70 0 25 -3 30 -10 19 -5 -8 -11 -12 -13 -10 -2 3 -25 34 -52 70 -27 38
-44 55 -40 40 4 -14 11 -29 14 -35 11 -16 12 -63 1 -70 -6 -4 -7 -13 -1 -23 5
-9 12 -51 16 -93 6 -66 4 -83 -14 -124 -12 -26 -21 -56 -21 -67 0 -10 -9 -36
-20 -58 -11 -22 -20 -44 -20 -51 0 -6 -9 -23 -19 -38 -10 -14 -24 -45 -30 -68
-6 -23 -16 -45 -21 -48 -6 -3 -10 -11 -10 -18 0 -6 6 -4 13 5 6 9 22 19 35 23
18 5 22 2 22 -15 0 -11 -7 -23 -15 -26 -8 -4 -15 -12 -15 -20 0 -7 -6 -17 -13
-21 -7 -4 -19 -23 -26 -40 -8 -18 -17 -33 -22 -33 -5 0 -14 -11 -21 -25 -15
-33 -28 -22 -28 26 0 21 -5 39 -10 39 -6 0 -15 11 -22 25 -14 32 -28 23 -28
-20 0 -45 -9 -65 -29 -65 -26 0 -29 7 -22 43 7 32 -6 77 -23 77 -9 0 -49 -32
-116 -94 -25 -22 -79 -70 -120 -106 -41 -36 -83 -73 -94 -82 -10 -10 -59 -50
-107 -90 -98 -81 -115 -111 -79 -140 l22 -18 -24 0 c-13 0 -29 -5 -35 -11 -9
-9 -11 -7 -7 8 5 19 -1 21 -98 27 -57 4 -114 8 -128 9 -14 1 -46 10 -70 21
-75 31 -84 35 -107 41 -12 3 -33 19 -47 35 l-24 29 -23 -19 c-12 -11 -27 -20
-33 -20 -55 1 -91 48 -76 100 5 17 14 38 21 46 7 8 7 14 1 14 -5 0 -15 -7 -22
-15 -16 -19 -30 -19 -30 0 0 16 -34 38 -44 28 -11 -12 -57 20 -79 56 -12 20
-30 45 -40 56 -27 29 -77 102 -77 113 0 5 -6 15 -13 21 -8 6 -22 38 -32 71
-10 33 -21 60 -26 60 -6 0 -16 46 -22 108 -1 13 -7 20 -14 17 -15 -5 -17 28
-4 54 5 9 6 23 2 30 -6 9 -10 6 -14 -10 -4 -13 -8 -21 -11 -19 -6 6 19 100 41
160 16 42 41 91 74 150 58 101 214 272 277 303 27 13 122 12 122 -2 0 -3 -18
-31 -41 -61 -23 -30 -59 -87 -81 -127 -37 -68 -41 -72 -78 -75 l-38 -3 71
-283 c64 -253 73 -281 83 -256 l11 27 8 -39 c7 -36 8 -37 15 -14 5 14 8 42 9
63 1 48 37 221 63 307 30 96 118 275 178 360 71 99 75 77 6 -29 -96 -145 -176
-376 -210 -601 -16 -108 -14 -129 10 -109 11 9 14 8 14 -5 0 -28 23 -17 86 42
72 68 95 88 292 261 115 102 152 140 152 157 0 17 -6 24 -20 24 -11 0 -20 6
-20 14 0 20 99 165 121 177 10 5 19 16 19 24 0 19 99 125 117 125 7 0 16 8 20
18 8 23 47 57 116 99 62 39 68 48 37 58 -22 7 -105 -47 -209 -134 -63 -53 -54
-27 13 39 25 25 46 48 46 53 0 4 -11 13 -25 19 -41 18 -28 58 28 87 15 8 27
18 27 22 0 4 35 22 78 41 72 31 84 33 189 33 73 0 104 3 90 9 -12 4 -71 8
-132 9 -132 0 -244 -21 -368 -70 -67 -26 -95 -33 -120 -27 l-32 7 50 23 c28
13 57 23 66 24 9 0 19 3 23 8 4 4 33 15 64 24 56 16 43 21 -33 11z m-217 -222
c-4 -11 -127 -148 -155 -174 -13 -12 -23 -24 -23 -29 0 -9 -25 -36 -72 -77
-41 -36 -34 -12 21 71 56 83 144 178 187 201 33 18 47 21 42 8z m302 5 c0 -3
-4 -8 -10 -11 -5 -3 -10 -1 -10 4 0 6 5 11 10 11 6 0 10 -2 10 -4z m-660 -60
c0 -2 -7 -7 -16 -10 -8 -3 -12 -2 -9 4 6 10 25 14 25 6z m199 -33 c-18 -21
-35 -40 -36 -42 -2 -2 -5 -2 -8 1 -5 5 64 78 74 78 3 0 -11 -17 -30 -37z m50
-537 c-31 -29 -71 -63 -89 -76 -73 -52 24 58 104 118 52 40 42 11 -15 -42z
m1185 -318 c9 -12 16 -30 16 -40 0 -22 -34 -58 -55 -58 -19 0 -55 39 -55 60 0
27 30 60 55 60 13 0 31 -10 39 -22z m-455 -254 c17 11 14 -78 -4 -120 -10 -24
-20 -34 -32 -32 -11 2 -19 -4 -21 -17 -2 -11 -7 -29 -11 -40 -5 -11 -19 -55
-32 -97 -13 -43 -27 -78 -31 -78 -5 0 -8 -7 -8 -15 0 -23 -77 -174 -136 -265
-36 -56 -102 -120 -118 -114 -8 3 -17 1 -21 -5 -4 -7 1 -9 12 -5 14 4 13 2 -4
-14 -14 -12 -31 -18 -43 -15 -14 4 -20 0 -20 -11 0 -20 -19 -21 -26 -1 -3 8 4
26 15 41 12 15 21 34 21 44 0 9 3 20 8 24 4 4 8 12 8 17 1 5 2 14 3 19 0 6 8
10 17 10 12 0 14 7 9 33 -4 17 -13 38 -20 46 -11 11 -14 10 -19 -7 -7 -20 -36
-39 -36 -23 0 5 3 11 8 13 19 8 115 205 107 218 -4 6 -16 10 -28 10 -12 1 -43
6 -68 13 -39 10 -44 14 -32 25 7 7 11 17 8 22 -3 4 2 10 10 14 8 3 15 11 15
19 0 36 212 217 255 217 9 0 14 4 10 9 -3 5 -1 13 5 16 6 4 9 1 8 -7 -2 -8 12
-15 37 -19 22 -3 33 -7 25 -8 -13 -1 -13 -2 0 -11 9 -6 17 -5 22 3 5 8 8 4 8
-10 0 -12 5 -25 10 -28 6 -3 10 -1 10 5 0 7 9 23 19 36 11 14 22 43 26 66 5
25 10 36 15 28 4 -7 13 -9 19 -6z m-1712 -306 c-3 -8 -6 -5 -6 6 -1 11 2 17 5
13 3 -3 4 -12 1 -19z m1816 -3 c-3 -9 -8 -14 -10 -11 -3 3 -2 9 2 15 9 16 15
13 8 -4z m24 -67 c-3 -8 -6 -5 -6 6 -1 11 2 17 5 13 3 -3 4 -12 1 -19z m-330
-318 c-1 -20 -9 -25 -20 -13 -4 3 -2 12 4 20 15 18 18 17 16 -7z m-570 -166
c-3 -3 -12 -4 -19 -1 -8 3 -5 6 6 6 11 1 17 -2 13 -5z m16 -19 c-3 -9 -8 -14
-10 -11 -3 3 -2 9 2 15 9 16 15 13 8 -4z"/>
<path d="M5800 6110 c0 -5 6 -10 14 -10 8 0 18 5 21 10 3 6 -3 10 -14 10 -12
0 -21 -4 -21 -10z"/>
<path d="M6175 7610 c27 -12 43 -12 25 0 -8 5 -22 9 -30 9 -10 0 -8 -3 5 -9z"/>
<path d="M6225 7579 c-4 -6 -4 -13 0 -17 3 -3 10 0 15 8 6 8 10 11 10 5 0 -24
-21 -30 -106 -30 -70 0 -108 -6 -167 -25 -42 -14 -77 -27 -77 -30 0 -3 38 -11
85 -17 53 -7 115 -24 163 -45 94 -41 173 -97 166 -118 -3 -8 -2 -11 2 -7 9 9
35 -13 28 -24 -3 -5 2 -6 10 -3 21 8 132 -136 183 -239 75 -149 113 -300 113
-451 0 -82 -24 -216 -51 -286 -11 -30 -23 -64 -25 -75 -2 -11 -11 -65 -20
-120 -13 -81 -26 -118 -65 -194 -97 -185 -227 -332 -386 -436 -107 -71 -120
-82 -68 -59 71 33 188 106 256 160 105 84 192 171 183 185 -4 7 -3 9 3 6 6 -4
18 2 28 13 22 24 14 37 -9 15 -15 -15 -16 -14 -16 15 0 18 4 29 10 25 6 -3 10
4 10 16 0 12 7 27 16 34 9 6 18 22 19 36 4 38 4 39 20 39 8 0 15 -6 15 -14 0
-8 5 -18 12 -22 8 -5 9 2 5 23 -5 25 -2 32 14 36 16 4 19 2 14 -16 -5 -19 -5
-20 5 -4 6 9 16 14 22 11 7 -5 9 -1 5 9 -3 10 -2 17 2 17 13 0 64 85 99 166
128 292 132 653 10 884 -107 199 -269 385 -432 494 -72 47 -90 54 -101 35z"/>
<path d="M6108 7393 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M5713 7245 c0 -22 2 -30 4 -17 2 12 2 30 0 40 -3 9 -5 -1 -4 -23z"/>
<path d="M5702 7170 c0 -14 2 -19 5 -12 2 6 2 18 0 25 -3 6 -5 1 -5 -13z"/>
<path d="M5691 7133 c-1 -7 -9 -13 -18 -13 -16 0 -16 -1 0 -12 14 -11 15 -20
6 -57 -5 -24 -8 -51 -6 -60 2 -9 7 7 11 36 4 28 14 54 21 56 21 9 53 -20 53
-48 0 -24 17 -55 32 -55 4 0 6 17 5 37 -3 37 14 64 45 76 9 3 3 6 -13 6 -15 1
-36 -4 -46 -11 -25 -16 -68 3 -81 34 -5 13 -9 17 -9 11z"/>
<path d="M6432 7050 c0 -19 2 -27 5 -17 2 9 2 25 0 35 -3 9 -5 1 -5 -18z"/>
<path d="M5591 6674 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M4323 6265 c0 -33 2 -45 4 -27 2 18 2 45 0 60 -2 15 -4 0 -4 -33z"/>
<path d="M5457 6253 c-5 -7 -21 -13 -37 -13 -16 0 -30 -7 -34 -15 -4 -13 2
-14 37 -9 50 8 50 8 45 31 -2 13 -6 15 -11 6z"/>
<path d="M4130 5989 c0 -5 5 -7 10 -4 6 3 10 8 10 11 0 2 -4 4 -10 4 -5 0 -10
-5 -10 -11z"/>
<path d="M5887 5523 c-10 -9 -9 -23 2 -23 5 0 11 7 15 15 5 15 -5 20 -17 8z"/>
<path d="M4189 4723 c-41 -6 -104 -66 -117 -111 -26 -86 -10 -175 40 -228 l29
-31 -113 -8 c-80 -6 -104 -10 -83 -16 42 -9 3029 -9 3085 0 l45 8 -62 2 c-80
1 -93 7 -93 41 0 18 -6 29 -18 32 -18 5 -20 11 -78 211 l-28 97 -88 0 c-98 0
-111 -9 -89 -57 10 -23 7 -44 -20 -139 -22 -79 -37 -114 -48 -116 -10 -2 -16
-13 -16 -28 0 -23 -4 -25 -57 -28 l-58 -3 0 31 c0 24 -4 30 -17 28 -16 -3 -18
10 -21 125 l-3 128 28 -3 c23 -2 29 -8 31 -35 3 -29 7 -33 33 -33 l29 0 0 64
0 64 -77 -3 c-43 -1 -91 -6 -107 -10 -27 -7 -28 -9 -11 -21 10 -8 16 -16 13
-20 -4 -3 -16 4 -27 15 -26 26 -64 28 -59 4 2 -11 15 -20 33 -23 l30 -5 3
-110 c3 -126 -1 -157 -23 -149 -10 4 -15 0 -15 -11 0 -9 5 -13 10 -10 6 3 10
2 10 -4 0 -5 10 -12 23 -14 12 -2 -322 -4 -743 -4 -421 0 -745 3 -720 5 l45 5
-40 13 c-29 9 -33 13 -17 13 17 1 22 6 20 19 -2 9 -10 16 -16 14 -8 -1 -12 12
-12 38 0 22 -4 40 -10 40 -17 0 -6 24 20 42 20 14 21 18 8 18 -10 0 -18 7 -18
16 0 8 5 12 10 9 6 -3 10 1 10 9 0 9 -4 16 -10 16 -18 0 -11 30 8 31 13 0 12
3 -6 11 l-23 12 28 11 28 12 -40 11 c-22 7 -54 12 -71 12 l-31 0 -19 -92 c-11
-51 -24 -92 -29 -91 -6 1 -22 43 -36 95 l-25 93 -84 3 c-46 2 -87 -1 -93 -7
-6 -6 -3 -21 7 -42 22 -43 23 -243 2 -265 -8 -7 -12 -23 -9 -34 5 -18 1 -20
-51 -20 -31 0 -56 3 -56 7 0 4 10 17 23 28 48 44 65 114 47 199 -12 60 -38 99
-78 120 -32 16 -96 25 -133 19z m2071 -43 c0 -5 -4 -10 -10 -10 -5 0 -10 5
-10 10 0 6 5 10 10 10 6 0 10 -4 10 -10z m-1987 -70 c14 -27 17 -50 14 -98 -5
-71 -19 -94 -56 -90 -34 4 -51 40 -51 111 0 110 53 154 93 77z m474 8 c-3 -8
-6 -5 -6 6 -1 11 2 17 5 13 3 -3 4 -12 1 -19z m45 -104 c0 -160 1 -152 -17
-128 -14 18 -14 18 -15 -8 0 -24 -4 -28 -30 -28 -33 0 -34 2 -21 28 5 9 15 44
21 77 7 33 16 71 20 85 5 14 11 42 15 64 12 72 27 22 27 -90z m1936 67 c19
-61 16 -71 -18 -71 -34 0 -37 11 -19 73 13 47 23 47 37 -2z m-2123 -212 c5
-15 1 -19 -19 -19 -22 0 -26 4 -26 30 0 20 -5 30 -15 30 -12 0 -15 17 -15 93
0 52 3 97 7 101 4 3 19 -44 34 -105 16 -61 31 -119 34 -130z m1712 159 c-3 -8
-6 -5 -6 6 -1 11 2 17 5 13 3 -3 4 -12 1 -19z m20 -10 c-3 -8 -6 -5 -6 6 -1
11 2 17 5 13 3 -3 4 -12 1 -19z m421 -67 c20 -2 14 -41 -7 -41 -13 0 -20 -9
-24 -30 -5 -21 -12 -30 -27 -30 -15 0 -20 7 -20 29 0 21 -5 31 -19 33 -14 2
-17 8 -11 23 7 19 40 24 108 16z m-438 -46 c0 -8 -4 -15 -10 -15 -5 0 -7 7 -4
15 4 8 8 15 10 15 2 0 4 -7 4 -15z"/>
<path d="M4831 4474 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
        </g>
      </svg>
    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>;
}
